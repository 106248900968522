<template>
  <div class="my_container">
    <div class="breadcrumb_wrap">
      <div class="m_1200">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>个人中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="banner"></div>
    <img src="../assets/images/my_3.png" alt="" class="avatar">
    <p class="name">用户0{{userinfo.id}}</p>
    <p class="phone">{{userinfo.phone}}</p>
    <ul class="tbas_box">
      <li class="item" :class="{ active: current === 0 }" @click="handleTabs(0)">我的资料</li>
      <!-- <li class="item" :class="{ active: current === 1 }" @click="handleTabs(1)">我的预约</li> -->
    </ul>
    <!-- <div class="userinfo m_1200" v-if="current === 0">
      <div class="item">
        <p class="number">14</p>
        <p class="label">我的咨询</p>
      </div>
      <div class="item">
        <p class="number">14</p>
        <p class="label">我的报告</p>
      </div>
      <div class="item">
        <p class="number">14</p>
        <p class="label">我的收藏</p>
      </div>
    </div> -->
    <ul class="subscribe_list" v-if="current === 1">
      <li class="list_item" v-for="(item,index) in 6" :key="index">
        <div class="left">
          <div class="text">咨询师：<span>罗文娟</span></div>
          <div class="text">咨询方式：<span>视频</span></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      current: 0,//当前tabs下标
	  userinfo:''
    }
  },
  mounted(){
	var userinfo = localStorage.getItem('userinfo')
	this.userinfo = JSON.parse(userinfo)
	console.log(this.userinfo,99)
	if(!userinfo){
		this.$router.push({name:'login'})
	}
  },
  methods: {
    //切换tbas
    handleTabs (index) {
      this.current = index
    }
  }
}
</script>


<style lang="scss">
.my_container {
  display: flex;
  flex-direction: column;
  padding-bottom: 350px;
  .breadcrumb_wrap {
    padding: 32px 0;
    background-color: #f5f5f5;
  }
  .banner {
    width: 100%;
    height: 240px;
    background: url('../assets/images/my_1.png') no-repeat center center / 100% 100%;
  }
  .avatar {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin: -80px auto 0;
  }
  .name {
    padding: 24px 0 15px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  .phone {
    color: #999999;
    font-size: 14px;
    text-align: center;
  }
  .tbas_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0 24px;
    .item {
      box-sizing: border-box;
      width: 202px;
      height: 42px;
      line-height: 42px;
      color: #E51418;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      border: 1px solid #E51418;
      border-radius: 3px;
      cursor: pointer;
      transition: .2s all linear;
      &+.item {
        margin-left: 35px;
      }
      &.active {
        color: #ffffff;
        background-color: #E51418;
      }
    }
  }
  .subscribe_list {
    width: 800px;
    margin: 0 auto;
    .list_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 32px 42px;
      color: #999;
      font-size: 14px;
      border-radius: 12px;
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(0,0,0,.1);
      .text+.text {
        margin-top: 17px;
      }
      span {
        color: #000;
        font-weight: bold;
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
          margin-top: 15px;
          color: #333333;
        }
      }
    }
  }
  .userinfo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    text-align: center;
    border-radius: 10px;
    background-color: #f5f5f5;
    .item + .item {
      margin-left: 132px;
    }
    .number {
      margin-bottom: 12px;
      color: #E51418;
      font-size: 24px;
      font-weight: bold;
    }
    .label {
      color: #666666;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 768px) {
  .my_container {
    display: flex;
    flex-direction: column;
    padding-bottom: 42px;
    .breadcrumb_wrap {
      padding: 15px;
      background-color: #f5f5f5;
    }
    .banner {
      width: 100%;
      height: 160px;
      background: url('../assets/images/my_1.png') no-repeat center center / 100% 100%;
    }
    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin: -40px auto 0;
    }
    .name {
      padding: 12px 0;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
    .phone {
      color: #999999;
      font-size: 14px;
      text-align: center;
    }
    .tbas_box {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      .item {
        box-sizing: border-box;
        width: 120px;
        height: 30px;
        line-height: 30px;
        color: #E51418;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        border: 1px solid #E51418;
        border-radius: 3px;
        cursor: pointer;
        transition: .2s all linear;
        &+.item {
          margin-left: 15px;
        }
        &.active {
          color: #ffffff;
          background-color: #E51418;
        }
      }
    }
    .subscribe_list {
      box-sizing: border-box;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      .list_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 15px;
        color: #999;
        font-size: 14px;
        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .text+.text {
          margin-top: 17px;
        }
        span {
          color: #000;
          font-weight: bold;
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: center;
          .text {
            margin-top: 15px;
            color: #333333;
          }
        }
      }
    }
    .userinfo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 0;
      text-align: center;
      border-radius: 10px;
      background-color: #f5f5f5;
      .item {
        padding: 0 24px;
      }
      .item + .item {
        margin-left: 0;
      }
      .number {
        margin-bottom: 12px;
        color: #E51418;
        font-size: 20px;
        font-weight: bold;
      }
      .label {
        color: #666666;
        font-size: 14px;
      }
    }
  }
}
</style>